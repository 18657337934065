import React from 'react';
import { SCROLL } from '../constant/Constant'; 

const ScrollDown = ({language}) => {
    const SCROLL_DOWN = SCROLL[language];
    return (
        <div className="scroll__down">
            <a href="#about" className="mouse__wrapper">
                <span className="home__scroll-name">{ SCROLL_DOWN }</span>
                <span className="mouse">
                    <span className="wheel"></span>
                </span>
            </a>
        </div>
    )
}

export default ScrollDown;