import React, { useState } from "react";
import "./Sidebar.css";
//import Logo from "../../assets/logo.svg";
//import LightLogo from "../../assets/light-logo.svg";

import {
    RiHome2Line,
    RiBriefcase2Line,
    RiChat3Line,
    RiMoonLine,
    RiSunLine,
    RiMenu2Line,
    RiFlagFill
} from "react-icons/ri";

const Sidebar = (props) => {
    const [toggle, showMenu] = useState(false);
    return (
        <>
            <aside className={toggle ? 'aside show-menu' : 'aside'}>
                <a href="#home" className="nav__logo">
                    {/*<img src={props.theme === 'light' ? LightLogo : Logo} alt="logo" /> */}
                </a>
                <nav className="nav">
                    <div className="nav__menu">
                        <ul className="nav__list">
                            <li className="nav__item">
                                <a href="#home" className="nav__link">
                                    <RiHome2Line className="nav__icon" />
                                </a>
                            </li>
                            <li className="nav__item">
                                <a href="#resume" className="nav__link">
                                    <RiBriefcase2Line className="nav__icon" />
                                </a>
                            </li>
                            <li className="nav__item">
                                <a href="#contact" className="nav__link">
                                    <RiChat3Line className="nav__icon" />
                                </a>
                            </li>
                            <li className="nav__item">
                                <button onClick={props.switchLanguage} className="nav__link footer__button">
                                    <RiFlagFill className="nav__icon" />
                                </button>
                            </li>
                        </ul>
                    </div>
                </nav>

                <div className="nav__footer">
                    <button onClick={() => { props.switchTheme(); showMenu(!toggle) }} className="nav__link footer__button">
                        {props.theme === 'light' ? <RiMoonLine className="nav__icon" /> : <RiSunLine className="nav__icon" />}
                    </button>
                </div>
            </aside>

            <div className={toggle ? 'nav__toggle nav__toggle-open' : 'nav__toggle'} onClick={() => showMenu(!toggle)}>
                <RiMenu2Line />
            </div>
        </>
    );
};

export default Sidebar;
