import React from 'react';
import './Footer.css';
import { PHRASE_FOOTER } from '../constant/Constant'; 

const Footer = ({ language }) => {
    const FOOTER_RESERVE = PHRASE_FOOTER[language];
    return (
        <footer className="footer">
            <p>&copy; {new Date().getFullYear()} {FOOTER_RESERVE}</p>
        </footer>
    );
};

export default Footer;
