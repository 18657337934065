const WorkExperience = [
    {
        id: 1,
        company: {
            es: "INDAP Desarrollador",
            en: "INDAP developer"
        },
        yearsActive: {
            es: "Agosto 2024 - Presente",
            en: "August 2024 - Present"
        },
        title: {
            es: "Desarrollador",
            en: "Developer"
        },
        information: {
            es: [
                "Desarrollo y mantenimiento de aplicaciones web, utilizando PHP, JavaScript y MySQL",
                "Gestión de bases de datos y formularios digitales para la recogida de datos",
                "Colaboración con el equipo técnico para mejorar la funcionalidad y eficiencia de los sistemas informáticos existentes"
            ],
            en: [
                "Development and maintenance of web applications, using PHP, JavaScript and MySQL",
                "Management of databases and digital forms for data collection",
                "Collaboration with the technical team to improve the functionality and efficiency of existing computer systems"
            ]
        }
    },
    {
        id: 2,
        company: {
            es: "INDAP Ingeniero",
            en: "INDAP engineer"
        },
        yearsActive: {
            es: "Enero 2019 - Julio 2024",
            en: "January 2019 - July 2024"
        },
        title: {
            es: "Prevencionista de Riesgos",
            en: "Safety Specialists"
        },
        information: {
            es: [
                "Desarrollo de programas de seguridad y capacitación del personal en prácticas seguras y procedimientos de emergencias",
                "Investigación de incidentes, elaboración de informes y recomendaciones, medidas preventivas y correctivas",
                "Inspección y Evaluación para identificar riesgos y asegurar el cumplimiento de las normas de seguridad"
            ],
            en: [
                "Development of security programs and training of personnel in safe practices and emergency procedures",
                "Incident investigation, preparation of reports and recommendations, preventive and corrective measures",
                "Inspection and Evaluation to identify risks and ensure compliance with safety regulations"
            ]
        }  
    },
    {
        id: 3,
        company: {
            es: "CFT La Araucana",
            en: "TTC La Araucana"
        },
        yearsActive: {
            es: "Julio 2017 - Diciembre 2017",
            en: "July 2017 - December 2017"
        },
        title: {
            es: "Profesor",
            en: "Teacher"
        },
        information: {
            es: [
                "ejecución de dos asignaturas, velando por la comprensión de los conceptos claves y el desarrollo de habilidades prácticas",
                "Docente orientador, apoyando a los estudiantes en su proceso académico y personal",
                "Miembro en comisiones de defensa de título, evaluando proyectos finales y garantizando la calidad de los trabajos presentados"
            ],
            en: [
                "execution of two subjects, ensuring the understanding of key concepts and the development of practical skills",
                "Guidance teacher, supporting students in their academic and personal process",
                "Member of degree defense committees, evaluating final projects and guaranteeing the quality of the works presented"
            ]
        }
    },
    {
        id: 4,
        company: {
            es: "San Antonio Terminal Internacional",
            en: "San Antonio International Terminal"
        },
        yearsActive: {
            es: "Julio 2018 - Enero 2019",
            en: "July 2008 - January 2019"
        },
        title: {
            es: "Prevencionista de riesgos",
            en: "Safety Specialists"
        },
        information: {
            es: [
                "Desarrollo de Programas Integrales de Seguridad para el control de accidentes y daños",
                "Capacitación y Concientización en Seguridad al personal portuario",
                "Gestión de Residuos, cumpliendo con la normatividad ambiental",
                "Respuesta a emergencias ante incidentes HAZMAT (materiales peligrosos), coordinar ejercicios de simulación para capacitar al personal en la identificación, contención y mitigación de riesgos asociados"
            ],
            en: [
                "Development of Comprehensive Safety Programs for accident and damage control",
                "Training and Security Awareness for port personnel",
                "Waste Management, complying with environmental regulations",
                "Emergency response to HAZMAT (hazardous materials) incidents, coordinate simulation exercises to train personnel in the identification, containment and mitigation of associated risks"
            ]
        }
    },
    {
        id: 5,
        company: {
            es: "Educación",
            en: "Education"
        },
        yearsActive: {
            es: "",
            en: ""
        },
        title: {
            es: "Ingeniero y Desarrollador",
            en: "Engineer & Developer"
        },
        information: {
            es: [
                "Instituto Profesional Duoc UC - Ingeniería en Informática / 2020-2023",
                "Universidad Técnica Federico Santa María - Ingeniería en prevención de riesgos laborales y ambientales / 2012-2016",
                "Capacitación en Seguridad Química - Técnico en HAZMAT / 2010",
                "Centro de Formación Técnica Santo Tomás - Técnico en prevención de riesgos / 2006-2008"
            ],
            en: [
                "Duoc UC Professional Institute - Computer engineering / 2020-2023",
                "Federico Santa Maria Technical University - Engineering in prevention of occupational and environmental risks / 2012-2016",
                "Cheminal Safety Training - HAZMAT technician / 2010",
                "Santo Tomás Technical Training Center - Risk prevention technician / 2006-2008"
            ]
        }
    }
];

export default WorkExperience;
