import React, { useState } from "react";
import "./Resume.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Button from "./Button";
import WorkExperience from "./WorkExperience";
import { EXPERIENCE, RESUME } from '../constant/Constant'; 
import Curriculum from '../../assets/curriculum.pdf';

const Resume = ({ language }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const downloadResume = async () => {
        window.open(Curriculum, '_blank');
    }
    const TITLE_EXPERIENCE = EXPERIENCE[language];
    const TITLE_CV = RESUME[language];
    return (
        <section className="resume container section" id="resume">
            <h2 className="section__title">{TITLE_EXPERIENCE}</h2>
            <div className="resume__button-container">
                <button className="btn" onClick={downloadResume}>{ TITLE_CV }</button>
            </div>
            <div className="resume__container">
                <Tabs
                    className="tabs"
                    selectedIndex={tabIndex}
                    onSelect={(index) => setTabIndex(index)}
                    selectedTabClassName={"is-active"}
                    selectedTabPanelClassName={"is-active"} 
                >
                    <TabList className="tab__list">
                        {WorkExperience.map((experience, index) => {
                            const { id, company } = experience;
                            return (
                                <Tab className="tab" key={`company-${id}`}>
                                    <Button>{company[language]}</Button>
                                </Tab>
                            );
                        })}
                    </TabList>

                    {WorkExperience.map((experience) => {
                        const { id, company, yearsActive, title, information } = experience;
                        return (
                            <TabPanel className="tab__panel" key={`panel-${id}`}>
                                <h2 className="tab__panel-title">
                                    {title[language]} @ {company[language]}
                                </h2>
                                <p className="tab__panel-subtitle">{yearsActive[language]}</p>
                                <ul className="tab__panel-list">
                                {information[language].map((info, index) => {
                                        return <li key={`info-${index}`}>{info}</li>;
                                    })}
                                </ul>
                            </TabPanel>
                        );
                    })}
                </Tabs>
            </div>
        </section>
    );
};

export default Resume;
