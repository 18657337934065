import React, { useEffect, useState } from 'react';
import './LoadPage.css';
import { LOAD } from '../constant/Constant'; 

const PixelEffect = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 1000); 

    return () => clearTimeout(timer);
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div className="pixel-effect">
      <h4 className='loading'>{ LOAD } </h4>
    </div>
  );
};

export default PixelEffect; 
