import React from 'react';
import './Home.css';
import HeaderSocials from './HeaderSocials';
import Name from './MainName';
import ScrollDown from './ScrollDown';
import Shapes from './Shapes';
import { PROFESSIONS, CONTACTS } from '../constant/Constant'; 

const Home = ({ language }) => {
    const PROFESSION_TITLE = PROFESSIONS[language];
    const CONTACT = CONTACTS[language];
    return (
        <section className="home container" id='home'>
            <div className="intro">
                <Name />
                <h3 className="profession"> {PROFESSION_TITLE} </h3>
                <HeaderSocials />
                <a href="#contact" className="btn"> {CONTACT} </a>
                <ScrollDown language={language}/>
            </div>
            <Shapes /> 
        </section> 
    )
}
export default Home 