import React, { useState, useEffect } from 'react';
import './MainName.css';

const Name = () => {
  // List of names
  const NAMES = ['Gustavo Cancino', 'アゾテア'];
  
  const [nameIndex, setNameIndex] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVisible(false);
      setTimeout(() => {
        setNameIndex(prevIndex => (prevIndex + 1) % NAMES.length);
        setVisible(true);
      }, 500);
    }, 3000);
    
    return () => clearInterval(intervalId);
  }, [NAMES.length]);

  return (
    <div className="App">
      <header className="App-header">
        <h6 className={`nombre ${visible ? 'visible' : 'invisible'}`}>{NAMES[nameIndex]}</h6>
      </header>
    </div>
  );
};

export default Name;
