
export const PROFESSIONS = {
    es: "Ingeniero y Desarrollador",
    en: "Engineer & Developer"
};
export const CONTACTS = {
    es: "Contáctame",
    en: "Contact me"
};
export const EXPERIENCE = {
    es: "Experiencia",
    en: "Experience"
};
export const RESUME = {
    es: "Curriculum",
    en: "Resume"
};
export const GET_TITLE = {
    es: "Ponerse en contacto",
    en: "Get In Touch"
};
export const GET_PHRASE = {
    es: "¡Hablemos de todo!",
    en: "Let's talk about everything!"
};
export const GET_QUESTION = {
    es: "¿No te gustan los formularios? Envíame un correo electrónico",
    en: "Don't like forms? Send me an email"
};
export const PHRASE_FOOTER = {
    es: "Gustavo Cancino Valderrama - Todos los derechos reservados",
    en: "Gustavo Cancino Valderrama - All rights reserved"
};
export const SCROLL = {
    es: "Desplazar hacia abajo",
    en: "Scroll Down"
};
export const LOAD = "♣   アゾテア   ♣";
export const PLACEHOLDERS = {
    name: {
        es: "Ingresa tu nombre",
        en: "Insert your name"
    },
    email: {
        es: "Ingresa tu correo electrónico",
        en: "Insert your email"
    },
    subject: {
        es: "Ingresa tu asunto",
        en: "Insert your subject"
    },
    message: {
        es: "Escribe tu mensaje",
        en: "Write your message"
    },
    sending: {
        es: "Enviando",
        en: "Sending"
    },
    send: {
        es: "Enviar Mensaje",
        en: "Send Message"
    }
};


