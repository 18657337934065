import './App.css';
import React, { useState } from 'react';
import Sidebar from './components/sidebar/Sidebar';
import Home from './components/home/Home';
import Resume from './components/resume/Resume';
import Contact from './components/contact/Contact';
import useLocalStorage from 'use-local-storage'
import LoadPage from './components/loadPage/LoadPage';
import Footer from './components/footer/Footer';

function App() {
    const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');
    const switchTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
    }
    const [language, setLanguage] = useState('es'); // es para español, en para inglés
    const switchLanguage = () => {
        const prevLanguage = language === 'es' ? 'en' : 'es';
        setLanguage(prevLanguage);
    }
    return (
        <div className="app" data-theme={theme}>
            <LoadPage />
            <Sidebar theme={theme} switchTheme={switchTheme} switchLanguage={switchLanguage} />
            <main className='main'>
                <Home language={language} />
                <Resume language={language} />
                <Contact theme={theme} language={language}/>
                <Footer language={language}/>
            </main>
            
        </div>
    );
}
export default App;

